
import {
  computed, defineComponent,
} from 'vue';

export default defineComponent({
  name: 'SelecionarStatusAssinatura',
  components: {
  },
  props: {
    valor: {
      type: Number || undefined,
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    limparSelecao: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    radioButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:valor', 'change'],
  setup(props, { emit }) {
    function obterValor():any {
      return props.valor;
    }

    const computedValor = computed({
      get: () => obterValor(),
      set: (valor: number) => {
        emit('update:valor', valor);
      },
    });

    function defineValorSelecionado(valor:number) {
      emit('update:valor', valor);
      emit('change', valor);
    }

    function defineValorMarcado(valor:number) {
      emit('change', valor);
    }

    return {
      props,
      computedValor,
      defineValorSelecionado,
      defineValorMarcado,
    };
  },
});
