
import { defineComponent, reactive, watch } from 'vue';
import draggable from 'vuedraggable';
import Icone from '@/core/components/Icone.vue';
import SelecionarIdentificadorModulo from '@/components/PainelAdministrativo/SelecionarIdentificadorModulo.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import ServicoModulo from '@/servicos/PainelAdministrativo/ServicoModulo';
import ServicoPermissao from '@/servicos/PainelAdministrativo/Permissoes/ServicoPermissao';
import storeSistema from '@/store/storeSistema';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IOption } from '@/core/models/AntDesign/IOption';
import { IDTOModuloPermissao } from '@/models/DTO/MeuSistema/Modulos/IDTOModuloPermissao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IModulo } from '@/models/Entidades/PainelAdministrativo/Modulos/IModulo';

export default defineComponent({
  name: 'ModuloModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    draggable,
    Icone,
    SelecionarIdentificadorModulo,
  },
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados,
      preencherPermissoesDados, filtrarPermissaoDadosUsuario, apresentarMensagemSucesso, apresentarMensagemAlerta,
    } = useTelaBase();
    const { modalBase, apresentarRetornoRequisicao } = useModalBase(props, emit);
    const servicoModulo = new ServicoModulo();
    const servicoPermissao = new ServicoPermissao();

    telaBase.identificadorRecurso = 'CADASTRO_MODULOS';
    telaBase.identificadorPermissao = 'PER_CADASTRO_MODULOS';

    const state = reactive({
      modulo: {} as IModulo,
      atualizarObjetoPrincipal: false,
      atualizarPermissoes: false,
      abaAtiva: 1,
      codigoPermissao: undefined,
      listaPermissoes: [] as IOption[],
      permissoesDadosModulo: [] as IDTOModuloPermissao[],
      permissoesAutorizacoesModulo: [] as IDTOModuloPermissao[],
      permissoesWidgetsModulo: [] as IDTOModuloPermissao[],
      permissoesRelatoriosModulo: [] as IDTOModuloPermissao[],
    });

    function prepararTela() {
      state.abaAtiva = 1;
      state.modulo = {} as IModulo;
      state.atualizarObjetoPrincipal = false;
      state.atualizarPermissoes = false;
      state.listaPermissoes = [];
      state.codigoPermissao = undefined;
      state.permissoesDadosModulo = [];
      state.permissoesAutorizacoesModulo = [];
      state.permissoesWidgetsModulo = [];
      state.permissoesRelatoriosModulo = [];
    }

    function limparPesquisa() {
      state.listaPermissoes = [];
      state.codigoPermissao = undefined;
    }
    async function pesquisarPermissao(valorPesquisa: any, tipoPermissao: number) {
      state.listaPermissoes = [];
      const timeOut = 600;
      clearTimeout(telaBase.debounce);
      telaBase.debounce = setTimeout(async () => {
        const parametrosConsultaRapida: IParametrosConsultaRapida = {
          valor: valorPesquisa, recursoAssociado: 'CADASTRO_PERMISSOES', filtrarPorCodigo: false, apenasAtivos: true,
        };
        const categorias = await servicoPermissao.consultaRapidaPorTipo(tipoPermissao, parametrosConsultaRapida);
        state.listaPermissoes = categorias.map((item) => ({
          label: item.textoIdentificacao,
          value: item.codigo,
        }));
      }, timeOut);
    }
    function verificaPermissao(tipoPermissao: number, permissoes: IDTOModuloPermissao[], codigoPermissao: number): boolean {
      if (UtilitarioGeral.validaLista(permissoes)) {
        const permissao = permissoes.find((c) => c.codigoPermissao === codigoPermissao);
        if (permissao !== undefined) {
          switch (tipoPermissao) {
            case ETipoPermissao.Dados:
              apresentarMensagemAlerta('Permissão já foi adicionada!');
              break;
            case ETipoPermissao.Autorizacoes:
              apresentarMensagemAlerta('Autorização já foi adicionada!');
              break;
            case ETipoPermissao.Widgets:
              apresentarMensagemAlerta('Widget já foi adicionado!');
              break;

            case ETipoPermissao.Relatorios:
              apresentarMensagemAlerta('Relatório já foi adicionado!');
              break;
            default:
          }

          return true;
        }
      }
      return false;
    }
    async function adicionarPermissao(tipoPermissao: number, codigoPermissao?: number) {
      const permissaoModulo: IDTOModuloPermissao = {} as IDTOModuloPermissao;
      permissaoModulo.tipoPermissao = tipoPermissao;
      if (!UtilitarioGeral.valorValido(codigoPermissao)) {
        apresentarMensagemAlerta('Selecione uma opção!');
        return;
      }
      if (codigoPermissao !== undefined) {
        permissaoModulo.codigoPermissao = codigoPermissao;
        const permissao = state.listaPermissoes.find((c) => c.value === codigoPermissao);
        if (permissao !== undefined) {
          permissaoModulo.descricaoPermissao = permissao.label;
        }
        const categoriaPermissao = await servicoPermissao.obterCategoriaPermissao(codigoPermissao);
        if (UtilitarioGeral.objetoValido(categoriaPermissao)) {
          permissaoModulo.codigoCategoriaPermissao = categoriaPermissao.codigo;
          permissaoModulo.descricaoCategoriaPermissao = categoriaPermissao.descricao;
        }

        switch (tipoPermissao) {
          case ETipoPermissao.Dados:
            if (!verificaPermissao(tipoPermissao, state.permissoesDadosModulo, codigoPermissao)) {
              state.permissoesDadosModulo.push(permissaoModulo);
              state.atualizarPermissoes = true;
              state.codigoPermissao = undefined;
            }
            break;
          case ETipoPermissao.Autorizacoes:
            if (!verificaPermissao(tipoPermissao, state.permissoesDadosModulo, codigoPermissao)) {
              state.permissoesAutorizacoesModulo.push(permissaoModulo);
              state.codigoPermissao = undefined;
              state.atualizarPermissoes = true;
            }
            break;
          case ETipoPermissao.Widgets:
            if (!verificaPermissao(tipoPermissao, state.permissoesDadosModulo, codigoPermissao)) {
              state.permissoesWidgetsModulo.push(permissaoModulo);
              state.codigoPermissao = undefined;
              state.atualizarPermissoes = true;
            }
            break;

          case ETipoPermissao.Relatorios:
            if (!verificaPermissao(tipoPermissao, state.permissoesDadosModulo, codigoPermissao)) {
              state.permissoesRelatoriosModulo.push(permissaoModulo);
              state.codigoPermissao = undefined;
              state.atualizarPermissoes = true;
            }
            break;
          default:
        }
      }
    }

    async function removerPermissao(tipoPermissao: number, codigoPermissao: number) {
      let permissoesModulo: IDTOModuloPermissao[] = [];
      switch (tipoPermissao) {
        case ETipoPermissao.Dados:
          permissoesModulo = state.permissoesDadosModulo;
          break;
        case ETipoPermissao.Autorizacoes:
          permissoesModulo = state.permissoesAutorizacoesModulo;
          break;
        case ETipoPermissao.Widgets:
          permissoesModulo = state.permissoesWidgetsModulo;
          break;

        case ETipoPermissao.Relatorios:
          permissoesModulo = state.permissoesRelatoriosModulo;
          break;
        default:
      }

      let permissaoLocalizada = permissoesModulo.find((permissao) => permissao.codigoPermissao === codigoPermissao);
      if (permissaoLocalizada === undefined) { permissaoLocalizada = {} as IDTOModuloPermissao; }
      const indice = permissoesModulo.indexOf(permissaoLocalizada);
      permissoesModulo.splice(indice, 1);
      state.atualizarPermissoes = true;
    }

    function prepararListaPermissoes(permissoes: IDTOModuloPermissao[]) {
      if (UtilitarioGeral.validaLista(permissoes)) {
        let ordem = 1;
        permissoes.forEach((permissao) => {
          const permissaoModulo: IDTOModuloPermissao = {} as IDTOModuloPermissao;
          permissaoModulo.codigo = permissao.codigo;
          permissaoModulo.codigoModulo = state.modulo.codigo;
          permissaoModulo.codigoPermissao = permissao.codigoPermissao;
          permissaoModulo.ordem = ordem;
          state.modulo.permissoes.push(permissaoModulo);
          ordem += 1;
        });
      }
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = {} as IRetornoRequisicao;
      retorno.status = EStatusRetornoRequisicao.Sucesso;
      let mensagemRetornoPrincipal = '';
      state.modulo.permissoes = [];

      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoModulo.incluir(state.modulo);
        if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
          state.modulo.codigo = retorno.codigoRegistro;
        }
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        if (state.atualizarObjetoPrincipal) {
          retorno = await servicoModulo.alterar(state.modulo);
        }
      }
      mensagemRetornoPrincipal = retorno.mensagem;

      if (retorno.status === EStatusRetornoRequisicao.Sucesso && state.atualizarPermissoes) {
        prepararListaPermissoes(state.permissoesDadosModulo);
        prepararListaPermissoes(state.permissoesAutorizacoesModulo);
        prepararListaPermissoes(state.permissoesWidgetsModulo);
        prepararListaPermissoes(state.permissoesRelatoriosModulo);
        retorno = await servicoModulo.atualizarPermissoes(state.modulo.codigo, state.modulo.permissoes);
      }

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (!state.atualizarObjetoPrincipal && state.atualizarPermissoes) {
          apresentarMensagemSucesso(retorno.mensagem);
        } else if (UtilitarioGeral.valorValido(mensagemRetornoPrincipal)) {
          apresentarMensagemSucesso(mensagemRetornoPrincipal);
        }

        if (salvarNovo) {
          prepararTela();
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
          modalBase.computedOperacao = telaOperacao;
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      prepararTela();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(EPermissaoDados.Incluir, false);
        telaBase.permissaoDados = filtrarPermissaoDadosUsuario(storeSistema.getters.codigoEmpresaOperacao());

        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          modalBase.textoBotaoSalvar = 'Salvar alterações';
          modalBase.textoBotaoSalvarNovo = 'Salvar e novo';
          state.modulo = await servicoModulo.obter(props.operacao.codigoRegistro);

          const permissoesModulo: IDTOModuloPermissao[] = await servicoModulo.obterPermissoes(state.modulo.codigo);
          if (permissoesModulo.length > 0) {
            state.permissoesDadosModulo = permissoesModulo.filter((permissao) => permissao.tipoPermissao === ETipoPermissao.Dados);
            state.permissoesAutorizacoesModulo = permissoesModulo.filter((permissao) => permissao.tipoPermissao === ETipoPermissao.Autorizacoes);
            state.permissoesWidgetsModulo = permissoesModulo.filter((permissao) => permissao.tipoPermissao === ETipoPermissao.Widgets);
            state.permissoesRelatoriosModulo = permissoesModulo.filter((permissao) => permissao.tipoPermissao === ETipoPermissao.Relatorios);
          }
        } else {
          modalBase.textoBotaoSalvar = 'Concluir cadastro';
          modalBase.textoBotaoSalvarNovo = 'Concluir e novo';
        }
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      prepararTela,
      ETipoPermissao,
      EPermissaoDados,
      pesquisarPermissao,
      limparPesquisa,
      adicionarPermissao,
      removerPermissao,
    };
  },
});
