
import { computed, defineComponent, reactive } from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { useTelaBase } from '@/core/composables/TelaBase';
import ServicoModulo from '@/servicos/PainelAdministrativo/ServicoModulo';

export default defineComponent({
  name: 'SelecionarIdentificadorModulo',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number,
      default: 0,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'change'],
  setup(props, { emit }) {
    const { montaOpcoesComListaItemGenerico } = useTelaBase();

    const servicoModulo = new ServicoModulo();

    const state = reactive({
      listaIdentificadoresApi: [] as IOption[],
      listaIdentificadores: [] as IOption[],
    });

    (async () => {
      const listaIdentificadores = await servicoModulo.obterListaIdentificadoresModulo();
      state.listaIdentificadoresApi = montaOpcoesComListaItemGenerico(listaIdentificadores);
      state.listaIdentificadores = state.listaIdentificadoresApi;
    })();

    const computedCodigoSelecionado = computed({
      get: () => props.codigoSelecionado,
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
      },
    });

    const computedCodigosSelecionados = computed({
      get: () => props.codigosSelecionados,
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    return {
      props,
      state,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      change,
    };
  },
});
