
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import SelecionarTipoAmbiente from '@/components/PainelAdministrativo/SelecionarTipoAmbiente.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { IAmbiente } from '@/models/Entidades/PainelAdministrativo/IAmbiente';
import storeSistema from '@/store/storeSistema';
import ServicoAmbiente from '@/servicos/PainelAdministrativo/ServicoAmbiente';

export default defineComponent({
  name: 'AmbienteModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    Card,
    SelecionarTipoAmbiente,
    CampoNumerico,
  },
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados,
      preencherPermissoesDados, filtrarPermissaoDadosUsuario, apresentarMensagemSucesso,
    } = useTelaBase();
    const { modalBase, apresentarRetornoRequisicao } = useModalBase(props, emit);
    const servicoAmbiente = new ServicoAmbiente();
    telaBase.identificadorRecurso = 'CADASTRO_AMBIENTES';
    telaBase.identificadorPermissao = 'PER_CADASTRO_AMBIENTES';

    const stateModal = reactive({
      ambiente: {} as IAmbiente,
    });

    function objetoInicial() {
      stateModal.ambiente = {} as IAmbiente;
      stateModal.ambiente.tipo = 2;
      stateModal.ambiente.versao = '1.0';
      stateModal.ambiente.configuracoes = 'Processador: \nMemória: \nEspaço: \nBackups: ';
      stateModal.ambiente.dadosAcesso = 'IP: \nPorta: \nUsuário: \nSenha: ';
      stateModal.ambiente.qtdMaximaContratantes = 0;
      stateModal.ambiente.ativo = true;
      stateModal.ambiente.custoMensal = 0;
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoAmbiente.incluir(stateModal.ambiente);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoAmbiente.alterar(stateModal.ambiente);
      }
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
          modalBase.computedOperacao = telaOperacao;
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(EPermissaoDados.Incluir, false);
        telaBase.permissaoDados = filtrarPermissaoDadosUsuario(storeSistema.getters.codigoEmpresaOperacao());

        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          modalBase.textoBotaoSalvar = 'Salvar alterações';
          modalBase.textoBotaoSalvarNovo = 'Salvar e novo';
          stateModal.ambiente = await servicoAmbiente.obter(props.operacao.codigoRegistro);
        } else {
          modalBase.textoBotaoSalvar = 'Concluir cadastro';
          modalBase.textoBotaoSalvarNovo = 'Concluir e novo';
        }
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      stateModal,
      salvar,
      objetoInicial,
      EPermissaoDados,
    };
  },
});
