
import {
  defineComponent, reactive, onBeforeMount, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import SelecionarAmbiente from '@/components/PainelAdministrativo/SelecionarAmbiente.vue';
import EditorHtml from '@/core/components/Tela/EditorHtml.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import SelecionarTipoPlanoAssinatura from '@/components/PainelAdministrativo/SelecionarTipoPlanoAssinatura.vue';
import SelecionarNivelAtendimentoAssinatura from '@/components/PainelAdministrativo/SelecionarNivelAtendimentoAssinatura.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { IPlanoAssinatura, IPlanoAssinaturaDetalhe, IPlanoAssinaturaModulo } from '@/models/Entidades/MeuSistema/PlanoAssinatura/IPlanoAssinatura';
import storeSistema from '@/store/storeSistema';
import ServicoPlanoAssinatura from '@/servicos/PainelAdministrativo/ServicoPlanoAssinatura';
import ServicoModulo from '@/servicos/PainelAdministrativo/ServicoModulo';
import { IDTOModuloSelecionado } from '@/models/DTO/PainelAdministrativo/IDTOModuloSelecionado';
import { IModulo } from '@/models/Entidades/PainelAdministrativo/Modulos/IModulo';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoUtilitario from '@/servicos/MeuSistema/ServicoUtilitario';

export default defineComponent({
  name: 'PlanoAssinaturaModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    Card,
    MensagemSemDados,
    EditorHtml,
    SelecionarTipoPlanoAssinatura,
    SelecionarNivelAtendimentoAssinatura,
    SelecionarSimNao,
    CampoNumerico,
    SelecionarAmbiente,
  },
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados,
      preencherPermissoesDados, filtrarPermissaoDadosUsuario, apresentarMensagemSucesso,
    } = useTelaBase();
    const { modalBase, apresentarRetornoRequisicao } = useModalBase(props, emit);
    const servicoPlanoAssinatura = new ServicoPlanoAssinatura();
    const servicoModulo = new ServicoModulo();
    const servicoUtilitario = new ServicoUtilitario();
    telaBase.identificadorRecurso = 'CADASTRO_PLANOS_ASSINATURA';
    telaBase.identificadorPermissao = 'PER_CADASTRO_PLANOS_ASSINATURA';

    const state = reactive({
      planoAssinatura: {} as IPlanoAssinatura,
      modulosSelecionados: [] as IDTOModuloSelecionado[],
      modulos: [] as IModulo[],
      apresentacaoEspaco: 'Bytes',
    });

    function objetoInicial() {
      state.apresentacaoEspaco = 'Bytes';
      state.planoAssinatura = {} as IPlanoAssinatura;
      state.planoAssinatura.tipo = 1;
      state.planoAssinatura.ativo = true;
      state.planoAssinatura.ambienteExclusivo = false;
      state.planoAssinatura.modulos = [];
      state.planoAssinatura.detalhes = [];
      state.planoAssinatura.limites = [
        {
          codigo: 0, codigoPlanoAssinatura: 0, titulo: 'Qtde. Usuários', identificador: 'USUARIOS', limite: 0, ordem: 1,
        },
        {
          codigo: 0, codigoPlanoAssinatura: 0, titulo: 'Espaço em Disco', identificador: 'ESPACO', limite: 0, ordem: 2,
        },
        {
          codigo: 0, codigoPlanoAssinatura: 0, titulo: 'Qtde. E-mails', identificador: 'EMAILS', limite: 0, ordem: 3,
        },
        {
          codigo: 0, codigoPlanoAssinatura: 0, titulo: 'Qtde. Títulos Financeiros', identificador: 'TITULOS_FINANCEIROS', limite: 0, ordem: 4,
        },
        {
          codigo: 0, codigoPlanoAssinatura: 0, titulo: 'Qtde. NF-e', identificador: 'NFES', limite: 0, ordem: 5,
        },
        {
          codigo: 0, codigoPlanoAssinatura: 0, titulo: 'Qtde. NFC-e', identificador: 'NFCES', limite: 0, ordem: 6,
        },
        {
          codigo: 0, codigoPlanoAssinatura: 0, titulo: 'Qtde. MDF-e', identificador: 'MDFES', limite: 0, ordem: 7,
        },
        {
          codigo: 0, codigoPlanoAssinatura: 0, titulo: 'Qtde. CT-e', identificador: 'CTES', limite: 0, ordem: 8,
        },
        {
          codigo: 0, codigoPlanoAssinatura: 0, titulo: 'Qtde. NFS-e', identificador: 'NFSES', limite: 0, ordem: 9,
        },
        {
          codigo: 0, codigoPlanoAssinatura: 0, titulo: 'Qtde. Dias Teste', identificador: 'DIAS_TESTE', limite: 0, ordem: 10,
        },
        {
          codigo: 0, codigoPlanoAssinatura: 0, titulo: 'Qtde. Dias Vencido', identificador: 'DIAS_VENCIDO', limite: 0, ordem: 11,
        },
      ];

      state.planoAssinatura.valores = [
        {
          codigo: 0, codigoPlanoAssinatura: 0, recorrencia: 1, valor: 0,
        },
        {
          codigo: 0, codigoPlanoAssinatura: 0, recorrencia: 2, valor: 0,
        },
        {
          codigo: 0, codigoPlanoAssinatura: 0, recorrencia: 3, valor: 0,
        },
        {
          codigo: 0, codigoPlanoAssinatura: 0, recorrencia: 4, valor: 0,
        },
      ];

      state.modulosSelecionados = [];
      state.modulos.forEach((modulo) => {
        const moduloSelecionado:IDTOModuloSelecionado = { codigo: modulo.codigo, titulo: modulo.titulo, selecionado: false };
        state.modulosSelecionados.push(moduloSelecionado);
      });
    }
    async function atualizarApresentacaoEspaco(tamanhoBytes:number) {
      state.apresentacaoEspaco = await servicoUtilitario.obtemTamanhoArquivo(tamanhoBytes);
    }
    function prepararListaModulos() {
      if (UtilitarioGeral.validaLista(state.modulosSelecionados)) {
        state.modulosSelecionados.forEach((modulo) => {
          if (modulo.selecionado) {
            if (!state.planoAssinatura.modulos.some((c) => c.codigoModulo === modulo.codigo)) {
              const planoAssinaturaModulo:IPlanoAssinaturaModulo = {} as IPlanoAssinaturaModulo;
              planoAssinaturaModulo.codigo = 0;
              planoAssinaturaModulo.codigoPlanoAssinatura = state.planoAssinatura.codigo;
              planoAssinaturaModulo.codigoModulo = modulo.codigo;
              state.planoAssinatura.modulos.push(planoAssinaturaModulo);
            }
          } else if (UtilitarioGeral.validaLista(state.planoAssinatura.modulos)) {
            const indexModulo = state.planoAssinatura.modulos.findIndex((c) => c.codigoModulo === modulo.codigo);
            if (indexModulo >= 0) {
              state.planoAssinatura.modulos.splice(indexModulo, 1);
            }
          }
        });
      }
    }
    function prepararListaDetalhes() {
      if (UtilitarioGeral.validaLista(state.planoAssinatura.detalhes)) {
        for (let index = 0; index < state.planoAssinatura.detalhes.length; index += 1) {
          state.planoAssinatura.detalhes[index].ordem = index;
        }
      }
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      prepararListaModulos();
      prepararListaDetalhes();
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoPlanoAssinatura.incluir(state.planoAssinatura);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoPlanoAssinatura.alterar(state.planoAssinatura);
      }
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
          modalBase.computedOperacao = telaOperacao;
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(EPermissaoDados.Incluir, false);
        telaBase.permissaoDados = filtrarPermissaoDadosUsuario(storeSistema.getters.codigoEmpresaOperacao());

        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          modalBase.textoBotaoSalvar = 'Salvar alterações';
          modalBase.textoBotaoSalvarNovo = 'Salvar e novo';
          state.planoAssinatura = await servicoPlanoAssinatura.obter(props.operacao.codigoRegistro);

          for (let index = 0; index < state.modulosSelecionados.length; index += 1) {
            if (state.planoAssinatura.modulos.some((c) => c.codigoModulo === state.modulosSelecionados[index].codigo)) {
              state.modulosSelecionados[index].selecionado = true;
            }
          }
        } else {
          modalBase.textoBotaoSalvar = 'Concluir cadastro';
          modalBase.textoBotaoSalvarNovo = 'Concluir e novo';
        }
      }
      telaBase.carregando = false;
    });

    onBeforeMount(async () => {
      state.modulos = await servicoModulo.obterTodos();
    });

    function obterTituloValor(reccorencia:number): string {
      let titulo = 'Valor ';
      switch (reccorencia) {
        case 1:
          titulo += ' Mensal';
          break;

        case 2:
          titulo += ' Trimestral';
          break;

        case 3:
          titulo += ' Semestral';
          break;

        case 4:
          titulo += ' Anual';
          break;

        default:
          break;
      }

      return titulo;
    }

    function adicionarDetalhe() {
      const detalhe = {} as IPlanoAssinaturaDetalhe;
      detalhe.codigo = 0;
      detalhe.ordem = state.planoAssinatura.detalhes.length + 1;
      detalhe.detalhe = '';
      state.planoAssinatura.detalhes.push(detalhe);
    }

    function removerDetalhe(index: number) {
      state.planoAssinatura.detalhes.splice(index, 1);
    }

    function removerTodosDetalhes() {
      state.planoAssinatura.detalhes = [] as IPlanoAssinaturaDetalhe[];
    }

    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      objetoInicial,
      EPermissaoDados,
      obterTituloValor,
      atualizarApresentacaoEspaco,
      adicionarDetalhe,
      removerDetalhe,
      removerTodosDetalhes,
    };
  },
});
