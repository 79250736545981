
import {
  defineComponent,
} from 'vue';

import TelaPadraoCrud from '@/core/components/TelaPadraoCrud.vue';
import { useCrudBase } from '@/core/composables/CrudBase';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import ServicoAmbiente from '@/servicos/PainelAdministrativo/ServicoAmbiente';
import AmbienteModal from './AmbienteModal.vue';

export default defineComponent({
  name: 'Ambiente',
  components: {
    TelaPadraoCrud,
    AmbienteModal,
  },
  setup() {
    const {
      crudBase, preencherEstrutura, exibirModal, sincronizaTelaCrud,
    } = useCrudBase();
    const servicoAmbiente = new ServicoAmbiente();

    crudBase.estrutura = preencherEstrutura();
    crudBase.estrutura.apresentarEmpresas = false;
    crudBase.estrutura.verificarCadastroCompartilhado = false;
    crudBase.estrutura.botaoPrincipalAcao = 'Novo';
    crudBase.estrutura.mensagemExclusao = 'Você confirma a exclusão do ambiente';
    crudBase.estrutura.colunasGrade = [
      {
        title: 'Nome', dataIndex: 'nome', key: 'NomeAmbiente', position: 0, visible: true, ordering: true, fixed: 'left', align: 'left', customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Identificação', dataIndex: 'identificacao', key: 'IndentificacaoAmbiente', position: 0, visible: true, ordering: false, align: 'left',
      },
      {
        title: 'Código', dataIndex: 'codigo', key: 'CodigoAmbiente', position: 1, visible: false,
      },
      {
        title: 'Ações', key: 'acoes', position: 5, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
      },
    ];
    crudBase.estrutura.ordenacaoPadraoGrade.push({ identificador: 'CodigoAmbiente', ordem: 'DESC' } as IOrdenacao);

    return {
      crudBase,
      servicoAmbiente,
      exibirModal,
      sincronizaTelaCrud,
    };
  },
});
