
import {
  defineComponent, reactive, onBeforeMount,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import TotalizadorDecorado from '@/core/components/Tela/TotalizadorDecorado.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarContratante from '@/components/PainelAdministrativo/SelecionarContratante.vue';
import SelecionarStatusCuradoriaCadastro from '@/components/PainelAdministrativo/SelecionarStatusCuradoriaCadastro.vue';
import PaisModal from '@/views/Cadastros/Localizacoes/Paises/PaisModal.vue';
import EstadoModal from '@/views/Cadastros/Localizacoes/Estados/EstadoModal.vue';
import CidadeModal from '@/views/Cadastros/Localizacoes/Cidades/CidadeModal.vue';
import BancoModal from '@/views/Cadastros/Financeiro/Bancos/BancoModal.vue';
import NcmModal from '@/views/Cadastros/Produtos/Ncms/NcmModal.vue';
import CfopModal from '@/views/Cadastros/Tributacoes/Cfops/CfopModal.vue';
import storeSistema from '@/store/storeSistema';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IParametrosConsultaCuradoriaCadastro } from '@/models/ParametrosRequisicao/PainelAdministrativo/IParametrosConsultaCuradoriaCadastro';
import { IDTOResumoCuradoriaCadastro } from '@/models/DTO/PainelAdministrativo/IDTOResumoCuradoriaCadastro';
import ServicoCuradoriaCadastro from '@/servicos/PainelAdministrativo/ServicoCuradoriaCadastro';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { ICuradoriaCadastro } from '@/models/Entidades/PainelAdministrativo/ICuradoriaCadastro';
import { EStatusCuradoriaCadastro } from '@/models/Enumeradores/PainelAdministrativo/EStatusCuradoriaCadastro';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { IPais } from '@/models/Entidades/Cadastros/Localizacoes/IPais';
import { IEstado } from '@/models/Entidades/Cadastros/Localizacoes/IEstado';
import { ICidade } from '@/models/Entidades/Cadastros/Localizacoes/ICidade';
import { IBanco } from '@/models/Entidades/Cadastros/Financeiro/IBanco';
import { INcm } from '@/models/Entidades/Cadastros/Produtos/INcm';
import { ICfop } from '@/models/Entidades/Cadastros/Tributacoes/ICfop';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';

export default defineComponent({
  name: 'CuradoriaCadastro',
  components: {
    Icone,
    Card,
    MensagemSemDados,
    RequisicaoModal,
    TotalizadorDecorado,
    SelecionarData,
    SelecionarContratante,
    SelecionarStatusCuradoriaCadastro,
    PaisModal,
    EstadoModal,
    CidadeModal,
    BancoModal,
    NcmModal,
    CfopModal,
  },
  emits: ['update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, adicionarAtalho, removerAtalho, telaOperacaoIncluir, apresentarMensagemSucesso,
    } = useTelaBase();

    const {
      modalBase, apresentarBarraProgresso, ocultarBarraProgresso, apresentarRetornoRequisicao,
    } = useModalBase(props, emit);

    const { gradeBase } = useGradeBase();
    gradeBase.layoutMobile = UtilitarioDispositivo.larguraTelaMobile();

    const servicoCuradoriaCadastro = new ServicoCuradoriaCadastro();
    const state = reactive({
      objetoCadastro: {} as any,
      curadoriaEmRevisao: {} as ICuradoriaCadastro,
      telaOperacao: {} as ITelaOperacao,
      curadorias: [] as ICuradoriaCadastro[],
      resumoCuradorias: [] as IDTOResumoCuradoriaCadastro[],
      filtros: {} as IParametrosConsultaCuradoriaCadastro,
      apresentarModalPais: false,
      apresentarModalEstado: false,
      apresentarModalCidade: false,
      apresentarModalBanco: false,
      apresentarModalNcm: false,
      apresentarModalCfop: false,
    });

    state.telaOperacao = telaOperacaoIncluir();

    function preencherColunas() {
      if (gradeBase.layoutMobile) {
        // Colunas - Cadastros
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'Codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Título', dataIndex: 'titulo', key: 'Assinatura', position: 2, visible: true,
          },
        ];
      } else {
        // Colunas - Assinaturas
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: true, width: 40, align: 'right',
          },
          {
            title: 'Recurso', dataIndex: 'recurso', key: 'Recurso', position: 2, visible: true, width: 80, ellipsis: true,
          },
          {
            title: 'Data', dataIndex: 'dataSolicitacao', key: 'DataSolicitacao', position: 3, visible: true, ordering: false, customRenderRow: ECustomRenderRow.MascararData, width: 40, align: 'center',
          },
          {
            title: 'Identificação/Contratante', dataIndex: 'identificacao', key: 'Identificacao', position: 4, visible: true, ellipsis: true, width: 150,
          },
          {
            title: 'Usuário', dataIndex: 'nomeUsuario', key: 'NomeUsuario', position: 5, visible: true, ellipsis: true, width: 150,
          },
          {
            title: 'Status', dataIndex: 'status', key: 'Status', position: 6, visible: true, align: 'center', width: 40, ellipsis: true,
          },
        ];
      }
    }

    function visualizarSolicitacaoCadastro(curadoriaCadastro: ICuradoriaCadastro) {
      state.curadoriaEmRevisao = curadoriaCadastro;

      switch (curadoriaCadastro.recurso) {
        case 'CADASTRO_PAISES':
          state.objetoCadastro = JSON.parse(curadoriaCadastro.dados) as IPais;
          state.apresentarModalPais = true;
          break;

        case 'CADASTRO_ESTADOS':
          state.objetoCadastro = JSON.parse(curadoriaCadastro.dados) as IEstado;
          state.apresentarModalEstado = true;
          break;

        case 'CADASTRO_CIDADES':
          state.objetoCadastro = JSON.parse(curadoriaCadastro.dados) as ICidade;
          state.apresentarModalCidade = true;
          break;

        case 'CADASTRO_BANCOS':
          state.objetoCadastro = JSON.parse(curadoriaCadastro.dados) as IBanco;
          state.apresentarModalBanco = true;
          break;

        case 'CADASTRO_NCMS':
          state.objetoCadastro = JSON.parse(curadoriaCadastro.dados) as INcm;
          state.apresentarModalNcm = true;
          break;

        case 'CADASTRO_CFOPS':
          state.objetoCadastro = JSON.parse(curadoriaCadastro.dados) as ICfop;
          state.apresentarModalCfop = true;
          break;

        default:
          break;
      }
    }

    async function acaoCuradoria(status: EStatusCuradoriaCadastro, objeto:any) {
      state.curadoriaEmRevisao.status = status;
      state.curadoriaEmRevisao.dados = JSON.stringify(objeto);

      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      apresentarBarraProgresso();
      if (status === EStatusCuradoriaCadastro.Aprovado) {
        retorno = await servicoCuradoriaCadastro.aprovar(state.curadoriaEmRevisao);
      } else if (status === EStatusCuradoriaCadastro.Reprovado) {
        retorno = await servicoCuradoriaCadastro.reprovar(state.curadoriaEmRevisao);
      }

      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        state.resumoCuradorias = await servicoCuradoriaCadastro.obterResumo();
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    async function buscarCuradoriasPorStatus(status: number) {
      state.filtros.quantidadeRegistros = 0;
      state.filtros.codigoContratante = undefined;
      state.filtros.dataSolicitacao = undefined;
      state.filtros.status = status;

      state.curadorias = await servicoCuradoriaCadastro.buscarCuradorias(state.filtros);
    }

    async function buscarCuradorias() {
      state.filtros.quantidadeRegistros = 100;
      state.curadorias = await servicoCuradoriaCadastro.buscarCuradorias(state.filtros);
    }

    function obtemValoResumo(status: EStatusCuradoriaCadastro) {
      if (UtilitarioGeral.validaLista(state.resumoCuradorias)) {
        const resumo = state.resumoCuradorias.find((c) => c.status === status);
        if (resumo !== undefined) {
          return resumo.quantidade;
        }
      }
      return 0;
    }

    function obterCorStatus(status:number): string {
      let cor = '';
      switch (status) {
        case 1:
          cor += '#03a9f4';
          break;

        case 2:
          cor += '#87e22d';
          break;

        case 3:
          cor += '#f44336';
          break;

        default:
          break;
      }

      return cor;
    }
    onBeforeMount(async () => {
      telaBase.carregando = true;
      telaBase.identificadorRecurso = 'CURADORIA_CADASTROS';
      telaBase.identificadorPermissao = 'AUT_CURADORIA_CADASTROS';
      preencherColunas();

      await obterPermissoes(ETipoPermissao.Autorizacoes);
      state.resumoCuradorias = await servicoCuradoriaCadastro.obterResumo();
      await buscarCuradorias();
      telaBase.carregando = false;
    });

    async function atualizarCuradorias() {
      state.resumoCuradorias = await servicoCuradoriaCadastro.obterResumo();
      await buscarCuradorias();
    }
    return {
      telaBase,
      modalBase,
      gradeBase,
      storeSistema,
      state,
      EStatusCuradoriaCadastro,
      ECustomRenderRow,
      UtilitarioData,
      adicionarAtalho,
      removerAtalho,
      buscarCuradorias,
      buscarCuradoriasPorStatus,
      obtemValoResumo,
      obterCorStatus,
      atualizarCuradorias,
      acaoCuradoria,
      visualizarSolicitacaoCadastro,
    };
  },
});
