import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ant-row" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_4 = { class: "ant-col ant-col-xs-24 ant-col-xl-18" }
const _hoisted_5 = { class: "ant-row" }
const _hoisted_6 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_7 = { class: "ant-row" }
const _hoisted_8 = { class: "ant-col ant-col-xs-24 ant-col-xl-18" }
const _hoisted_9 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_10 = { class: "ant-row" }
const _hoisted_11 = { class: "ant-col ant-col-xs-24 ant-col-xl-12" }
const _hoisted_12 = { class: "ant-col ant-col-xs-24 ant-col-xl-12" }
const _hoisted_13 = { class: "ant-row" }
const _hoisted_14 = { class: "ant-col ant-col-xs-24 ant-col-xl-18" }
const _hoisted_15 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_16 = { class: "ant-row" }
const _hoisted_17 = { class: "ant-col ant-col-xs-24 ant-col-xl-18" }
const _hoisted_18 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_selecionar_tipo_ambiente = _resolveComponent("selecionar-tipo-ambiente")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_card = _resolveComponent("card")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_requisicao_modal = _resolveComponent("requisicao-modal")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      keyboard: false,
      centered: "",
      visible: _ctx.modalBase.computedVisivel,
      "onUpdate:visible": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.modalBase.computedVisivel) = $event)),
      width: 900
    }, {
      title: _withCtx(() => [
        _createTextVNode(" Cadastro de Ambientes ")
      ]),
      closeIcon: _withCtx(() => [
        _createVNode(_component_icone, { nome: "voltar" })
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", null, [
          ((_ctx.props.operacao.tipoPermissaoDados === _ctx.EPermissaoDados.Incluir && _ctx.telaBase.permissaoDados.incluir)
                    || (_ctx.props.operacao.tipoPermissaoDados === _ctx.EPermissaoDados.Visualizar && _ctx.telaBase.permissaoDados.alterar))
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("button", {
                  type: "button",
                  class: "ant-btn ant-btn-lg ant-btn-secondary",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.salvar(true)))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvarNovo), 1)
                ]),
                _createElementVNode("button", {
                  type: "button",
                  class: "ant-btn ant-btn-lg ant-btn-primary ss-btn-acao-destaque",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.salvar(false)))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvar), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_skeleton, {
          loading: _ctx.telaBase.carregando
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, { layout: "vertical" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_a_form_item, {
                      label: "Tipo",
                      class: "ss-margin-campos"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_selecionar_tipo_ambiente, {
                          valor: _ctx.stateModal.ambiente.tipo,
                          "onUpdate:valor": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.stateModal.ambiente.tipo) = $event))
                        }, null, 8, ["valor"])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_a_form_item, {
                      label: "Nome",
                      class: "ss-margin-campos"
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          maxlength: "250",
                          class: "ant-input",
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.stateModal.ambiente.nome) = $event))
                        }, null, 512), [
                          [_vModelText, _ctx.stateModal.ambiente.nome]
                        ])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_a_form_item, {
                      label: "Identificação",
                      class: "ss-margin-campos"
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "ant-input",
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.stateModal.ambiente.identificacao) = $event))
                        }, null, 512), [
                          [_vModelText, _ctx.stateModal.ambiente.identificacao]
                        ])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createVNode(_component_card, { titulo: "DETALHES DATA CENTER/PROVEDOR" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_a_form_item, {
                          label: "Data Center",
                          class: "ss-margin-campos"
                        }, {
                          default: _withCtx(() => [
                            _withDirectives(_createElementVNode("input", {
                              type: "text",
                              maxlength: "250",
                              class: "ant-input",
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.stateModal.ambiente.dataCenter) = $event))
                            }, null, 512), [
                              [_vModelText, _ctx.stateModal.ambiente.dataCenter]
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_a_form_item, {
                          label: "Custo Mensal",
                          class: "ss-margin-campos"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_campo_numerico, {
                              valor: _ctx.stateModal.ambiente.custoMensal,
                              "onUpdate:valor": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.stateModal.ambiente.custoMensal) = $event)),
                              monetario: true
                            }, null, 8, ["valor"])
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_a_form_item, {
                          label: "Configurações",
                          class: "ss-margin-campos"
                        }, {
                          default: _withCtx(() => [
                            _withDirectives(_createElementVNode("textarea", {
                              rows: 5,
                              class: "ant-input",
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.stateModal.ambiente.configuracoes) = $event))
                            }, null, 512), [
                              [_vModelText, _ctx.stateModal.ambiente.configuracoes]
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_a_form_item, {
                          label: "Dados de Acesso",
                          class: "ss-margin-campos"
                        }, {
                          default: _withCtx(() => [
                            _withDirectives(_createElementVNode("textarea", {
                              rows: 5,
                              class: "ant-input",
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.stateModal.ambiente.dadosAcesso) = $event))
                            }, null, 512), [
                              [_vModelText, _ctx.stateModal.ambiente.dadosAcesso]
                            ])
                          ]),
                          _: 1
                        })
                      ])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_card, { titulo: "DETALHES DA APLICAÇÃO" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_a_form_item, {
                          label: "URL API",
                          class: "ss-margin-campos"
                        }, {
                          default: _withCtx(() => [
                            _withDirectives(_createElementVNode("input", {
                              type: "text",
                              class: "ant-input",
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.stateModal.ambiente.urlApi) = $event))
                            }, null, 512), [
                              [_vModelText, _ctx.stateModal.ambiente.urlApi]
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_a_form_item, {
                          label: "Versão",
                          class: "ss-margin-campos"
                        }, {
                          default: _withCtx(() => [
                            _withDirectives(_createElementVNode("input", {
                              type: "text",
                              class: "ant-input",
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.stateModal.ambiente.versao) = $event))
                            }, null, 512), [
                              [_vModelText, _ctx.stateModal.ambiente.versao]
                            ])
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_a_form_item, {
                          label: "Token Integração",
                          class: "ss-margin-campos"
                        }, {
                          default: _withCtx(() => [
                            _withDirectives(_createElementVNode("input", {
                              type: "text",
                              class: "ant-input",
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.stateModal.ambiente.tokenIntegracao) = $event))
                            }, null, 512), [
                              [_vModelText, _ctx.stateModal.ambiente.tokenIntegracao]
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _createVNode(_component_a_form_item, {
                          label: "Qtde Máxima Contratantes",
                          class: "ss-margin-campos"
                        }, {
                          default: _withCtx(() => [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              class: "ant-input",
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.stateModal.ambiente.qtdMaximaContratantes) = $event))
                            }, null, 512), [
                              [_vModelText, _ctx.stateModal.ambiente.qtdMaximaContratantes]
                            ])
                          ]),
                          _: 1
                        })
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["loading"]),
        _createVNode(_component_requisicao_modal, {
          visivel: _ctx.modalBase.exibirRequisicaoModal,
          "onUpdate:visivel": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.modalBase.exibirRequisicaoModal) = $event)),
          retornoRequisicao: _ctx.modalBase.retornoRequisicao
        }, null, 8, ["visivel", "retornoRequisicao"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}