
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoPais from '@/servicos/Cadastros/Localizacoes/ServicoPais';

export default defineComponent({
  name: 'SelecionarPais',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    siglaSelecionada: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    limparSelecao: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'update:siglaSelecionada', 'change', 'blur'],
  setup(props, { emit }) {
    const { montaOpcoesComListaConsultaRapida } = useTelaBase();
    const refSelecionarPais = ref<HTMLElement | null>(null);
    let debounce = 0;
    const selecionarPais = new ServicoPais();

    const state = reactive({
      listaPais: [] as IOption[],
      buscandoDados: false,
      emDigitacao: false,
    });

    async function obterPaisPorCodigo(valor: any) {
      if (state.emDigitacao) { return; }

      if (props.varios) {
        if (props.codigosSelecionados.length === 0) { return; }
      } else {
        if (!UtilitarioGeral.valorValido(valor)) { return; }
        if (!(valor > 0)) { return; }
        if (props.codigoSelecionado === 0) return;
      }
      let efetuarConsulta = true;

      if (UtilitarioGeral.validaLista(state.listaPais)) {
        if (props.varios) {
          const listaCodigosConsulta = state.listaPais.map((c) => c.value);
          const diferencas = props.codigosSelecionados.filter((c) => !listaCodigosConsulta.includes(c));
          if (!UtilitarioGeral.validaLista(diferencas)) {
            efetuarConsulta = false;
          }
        } else {
          const existeLista = state.listaPais.find((c) => c.value === props.codigoSelecionado);
          if (existeLista !== undefined) {
            efetuarConsulta = false;
          }
        }
      }

      if (!efetuarConsulta) return;

      state.listaPais = [];

      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        apenasAtivos: false, recursoAssociado: '',
      };

      if (props.varios) {
        parametrosConsultaRapida.filtrarPorVariosCodigos = true;
        parametrosConsultaRapida.valores = valor;
      } else {
        parametrosConsultaRapida.filtrarPorCodigo = true;
        parametrosConsultaRapida.valor = valor;
      }
      const listaPais = await selecionarPais.consultaRapida(parametrosConsultaRapida);
      state.listaPais = montaOpcoesComListaConsultaRapida(listaPais);
    }

    async function pesquisarPais(valorPesquisa: any) {
      state.emDigitacao = true;
      state.buscandoDados = true;
      state.listaPais = [];

      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida: IParametrosConsultaRapida = {
          valor: valorPesquisa, apenasAtivos: true, recursoAssociado: '', quantidadeRegistros: 10,
        };

        const listaPais = await selecionarPais.consultaRapida(parametrosConsultaRapida);
        state.buscandoDados = false;
        state.listaPais = montaOpcoesComListaConsultaRapida(listaPais);
      }, 600);
    }

    function obterPais():any {
      obterPaisPorCodigo(props.codigoSelecionado);
      return props.codigoSelecionado;
    }

    const computedCodigoSelecionado = computed({
      get: () => obterPais(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
      },
    });

    function obterPaises():number[] {
      obterPaisPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterPaises(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    function focus() {
      if (refSelecionarPais.value) {
        refSelecionarPais.value.focus();
      }
    }

    function blur() {
      state.emDigitacao = false;
      emit('blur');
    }

    return {
      props,
      state,
      refSelecionarPais,
      pesquisarPais,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      change,
      focus,
      blur,
    };
  },
});
