import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTOSincronizarAmbiente } from '@/models/DTO/PainelAdministrativo/IDTOSincronizarAmbiente';
/**
 * Serviço de Controle de Assinaturas
 * Fornece todas regras de negócios e lógicas relacionado a controle de assinaturas.
 */

class ServicoSincronizarAmbiente {
    endPoint = 'painel-administrativo/sincronizar-ambientes';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async iniciarSincronizacao(objeto: IDTOSincronizarAmbiente): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, objeto);
      return result.data;
    }
}
export default ServicoSincronizarAmbiente;
