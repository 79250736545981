
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import SelecionarCategoriaPermissao from '@/components/PainelAdministrativo/SelecionarCategoriaPermissao.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { IPermissao } from '@/models/Entidades/PainelAdministrativo/Permissoes/IPermissao';
import ServicoPermissao from '@/servicos/PainelAdministrativo/Permissoes/ServicoPermissao';
import storeSistema from '@/store/storeSistema';
import { IOption } from '@/core/models/AntDesign/IOption';

export default defineComponent({
  name: 'PermissaoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    SelecionarCategoriaPermissao,
  },
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados,
      preencherPermissoesDados, filtrarPermissaoDadosUsuario, apresentarMensagemSucesso,
    } = useTelaBase();
    const { modalBase, apresentarRetornoRequisicao } = useModalBase(props, emit);
    const servicoPermissao = new ServicoPermissao();
    telaBase.identificadorRecurso = 'CADASTRO_PERMISSOES';
    telaBase.identificadorPermissao = 'PER_CADASTRO_PERMISSOES';

    const state = reactive({
      permissao: {} as IPermissao,
      listaCategorias: [] as IOption[],
      listaTipoPermissao: [] as IOption[],
    });

    (async () => {
      const listaTipoPermissao = await servicoPermissao.obterListaTipoPermissao();
      state.listaTipoPermissao = listaTipoPermissao.map((item) => ({
        label: item.descricao,
        value: Number(item.identificador),
      }));
    })();

    function objetoInicial() {
      state.permissao = {} as IPermissao;
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoPermissao.incluir(state.permissao);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoPermissao.alterar(state.permissao);
      }
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
          modalBase.computedOperacao = telaOperacao;
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(EPermissaoDados.Incluir, false);
        telaBase.permissaoDados = filtrarPermissaoDadosUsuario(storeSistema.getters.codigoEmpresaOperacao());

        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          modalBase.textoBotaoSalvar = 'Salvar alterações';
          modalBase.textoBotaoSalvarNovo = 'Salvar e novo';
          state.permissao = await servicoPermissao.obter(props.operacao.codigoRegistro);
        } else {
          modalBase.textoBotaoSalvar = 'Concluir cadastro';
          modalBase.textoBotaoSalvarNovo = 'Concluir e novo';
        }
      }
      telaBase.carregando = false;
    });

    const filtrarOpcao = (input:any, option:any) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      objetoInicial,
      EPermissaoDados,
      filtrarOpcao,
    };
  },
});
