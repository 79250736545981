
import {
  defineComponent,
} from 'vue';

import TelaPadraoCrud from '@/core/components/TelaPadraoCrud.vue';
import { useCrudBase } from '@/core/composables/CrudBase';
import CategoriaPermissaoModal from '@/views/PainelAdministrativo/Permissoes/Categorias/CategoriaPermissaoModal.vue';
import ServicoCategoriaPermissao from '@/servicos/PainelAdministrativo/Permissoes/ServicoCategoriaPermissao';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';

export default defineComponent({
  name: 'CategoriaPermissao',
  components: {
    TelaPadraoCrud,
    CategoriaPermissaoModal,
  },
  setup() {
    const {
      crudBase, preencherEstrutura, exibirModal, sincronizaTelaCrud,
    } = useCrudBase();
    const servicoCategoriaPermissao = new ServicoCategoriaPermissao();

    crudBase.estrutura = preencherEstrutura();
    crudBase.estrutura.apresentarEmpresas = false;
    crudBase.estrutura.verificarCadastroCompartilhado = false;
    crudBase.estrutura.botaoPrincipalAcao = 'Nova categoria';
    crudBase.estrutura.mensagemExclusao = 'Você confirma a exclusão da categoria';
    crudBase.estrutura.colunasGrade = [
      {
        title: 'Descrição', dataIndex: 'descricao', key: 'DescricaoCategoriaPermissao', position: 0, visible: true, ordering: true, fixed: 'left', align: 'left', customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Código', dataIndex: 'codigo', key: 'CodigoCategoriaPermissao', position: 1, visible: false,
      },
      {
        title: 'Ações', key: 'acoes', position: 5, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
      },
    ];
    crudBase.estrutura.ordenacaoPadraoGrade.push({ identificador: 'CodigoCategoriaPermissao', ordem: 'DESC' } as IOrdenacao);

    return {
      crudBase,
      servicoCategoriaPermissao,
      exibirModal,
      sincronizaTelaCrud,
    };
  },
});
