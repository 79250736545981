
import { computed, defineComponent, reactive } from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import ServicoCategoriaPermissao from '@/servicos/PainelAdministrativo/Permissoes/ServicoCategoriaPermissao';

export default defineComponent({
  name: 'SelecionarCategoriaPermissao',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Digite para selecionar',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados'],
  setup(props, { emit }) {
    const { montaOpcoesComListaConsultaRapida } = useTelaBase();

    let debounce = 0;
    const servicoCategoriaPermissao = new ServicoCategoriaPermissao();

    const state = reactive({
      listaCategoriaPermissoes: [] as IOption[],
      fetching: false,
      emDigitacao: false,
    });

    async function obterCategoriaPermissaoPorCodigo(valor: any) {
      if (state.emDigitacao) { return; }
      if (!UtilitarioGeral.valorValido(valor)) { return; }
      if (!(valor > 0)) { return; }

      if (props.varios) {
        if (props.codigosSelecionados.length === 0) { return; }
      } else if (props.codigoSelecionado === 0) {
        return;
      }
      let efetuarConsulta = true;

      if (UtilitarioGeral.validaLista(state.listaCategoriaPermissoes)) {
        if (props.varios) {
          const listaCodigosConsulta = state.listaCategoriaPermissoes.map((c) => c.value);
          const diferencas = props.codigosSelecionados.filter((c) => !listaCodigosConsulta.includes(c));
          if (!UtilitarioGeral.validaLista(diferencas)) {
            efetuarConsulta = false;
          }
        } else {
          const existeLista = state.listaCategoriaPermissoes.find((c) => c.value === props.codigoSelecionado);
          if (existeLista !== undefined) {
            efetuarConsulta = false;
          }
        }
      }

      if (!efetuarConsulta) return;

      state.listaCategoriaPermissoes = [];

      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        apenasAtivos: false, recursoAssociado: '',
      };

      if (props.varios) {
        parametrosConsultaRapida.filtrarPorVariosCodigos = true;
        parametrosConsultaRapida.valores = valor;
      } else {
        parametrosConsultaRapida.filtrarPorCodigo = true;
        parametrosConsultaRapida.valor = valor;
      }
      const listaCategoriaPermissoes = await servicoCategoriaPermissao.consultaRapida(parametrosConsultaRapida);
      state.listaCategoriaPermissoes = montaOpcoesComListaConsultaRapida(listaCategoriaPermissoes);
    }

    async function pesquisarCategoriaPermissao(valorPesquisa: any) {
      state.emDigitacao = true;

      if (!UtilitarioGeral.valorValido(valorPesquisa)) { return; }

      state.listaCategoriaPermissoes = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida: IParametrosConsultaRapida = {
          valor: valorPesquisa, apenasAtivos: true, recursoAssociado: '',
        };

        parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();

        const listaCategoriaPermissoes = await servicoCategoriaPermissao.consultaRapida(parametrosConsultaRapida);
        state.listaCategoriaPermissoes = montaOpcoesComListaConsultaRapida(listaCategoriaPermissoes);
      }, 600);
    }

    function obterCategoriaPermissao():any {
      obterCategoriaPermissaoPorCodigo(props.codigoSelecionado);
      return (props.codigoSelecionado === 0 ? undefined : props.codigoSelecionado);
    }

    const computedCodigoSelecionado = computed({
      get: () => obterCategoriaPermissao(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
      },
    });

    function obterCategoriaPermissoes():number[] {
      obterCategoriaPermissaoPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterCategoriaPermissoes(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    return {
      props,
      state,
      pesquisarCategoriaPermissao,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
    };
  },
});
