
import {
  defineComponent, reactive, onBeforeMount,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import TotalizadorDecorado from '@/core/components/Tela/TotalizadorDecorado.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarContratante from '@/components/PainelAdministrativo/SelecionarContratante.vue';
import SelecionarPlanoAssinatura from '@/components/PainelAdministrativo/SelecionarPlanoAssinatura.vue';
import SelecionarStatusAssinatura from '@/components/PainelAdministrativo/SelecionarStatusAssinatura.vue';
import ControleAssinaturaModal from './ControleAssinaturaModal.vue';
import storeSistema from '@/store/storeSistema';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IParametrosConsultaControleAssinatura } from '@/models/ParametrosRequisicao/PainelAdministrativo/IParametrosConsultaControleAssinatura';
import { IDTOControleAssinatura } from '@/models/DTO/PainelAdministrativo/IDTOControleAssinatura';
import { IDTOResumoControleAssinatura } from '@/models/DTO/PainelAdministrativo/IDTOResumoControleAssinatura';
import ServicoControleAssinatura from '@/servicos/PainelAdministrativo/ServicoControleAssinatura';
import { EStatusAssinatura } from '@/models/Enumeradores/MeuSistema/EStatusAssinatura';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';

export default defineComponent({
  name: 'ControleAssinatura',
  components: {
    Icone,
    Card,
    MensagemSemDados,
    TotalizadorDecorado,
    SelecionarData,
    SelecionarContratante,
    SelecionarPlanoAssinatura,
    SelecionarStatusAssinatura,
    ControleAssinaturaModal,
  },
  setup() {
    const {
      telaBase, obterPermissoes, adicionarAtalho, removerAtalho,
    } = useTelaBase();

    const { gradeBase } = useGradeBase();
    gradeBase.layoutMobile = UtilitarioDispositivo.larguraTelaMobile();

    const servicoControleAssinatura = new ServicoControleAssinatura();
    const state = reactive({
      apresentarModal: false,
      codigoAssinatura: 0,
      assinaturas: [] as IDTOControleAssinatura[],
      resumoAssinaturas: [] as IDTOResumoControleAssinatura[],
      filtros: {} as IParametrosConsultaControleAssinatura,
    });

    function preencherColunas() {
      if (gradeBase.layoutMobile) {
        // Colunas - Títulos selecionados Baixa
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'Codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Título', dataIndex: 'titulo', key: 'Assinatura', position: 2, visible: true,
          },
        ];
      } else {
        // Colunas - Títulos selecionados Baixa
        gradeBase.colunas = [
          {
            title: 'Assinatura', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: true, width: 40, align: 'right',
          },
          {
            title: 'Contratante', dataIndex: 'contratante', key: 'Contratante', position: 2, visible: true, ellipsis: true, width: 150,
          },
          {
            title: 'Plano', dataIndex: 'plano', key: 'Plano', position: 4, visible: true, ellipsis: true, width: 90,
          },
          {
            title: 'Recorrência', dataIndex: 'recorrencia', key: 'Recorrencia', position: 5, visible: true, ellipsis: true, width: 40,
          },
          {
            title: 'Vencimento', dataIndex: 'dataVencimento', key: 'DataVencimento', position: 6, visible: true, ordering: false, customRenderRow: ECustomRenderRow.MascararData, width: 40, align: 'center',
          },
          {
            title: 'Valor R$', dataIndex: 'valor', key: 'Valor', position: 7, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 60,
          },
          {
            title: 'Status', dataIndex: 'status', key: 'Status', position: 8, visible: true, align: 'center', width: 40, ellipsis: true,
          },
        ];
      }
    }
    function visualizarAssinatura(codigo:number) {
      state.codigoAssinatura = codigo;
      state.apresentarModal = true;
    }
    async function buscarAssinaturasPorStatus(status: number) {
      state.filtros.quantidadeRegistros = 0;
      state.filtros.dataVencimento = undefined;
      state.filtros.codigoContratante = undefined;
      state.filtros.dataVencimento = undefined;
      state.filtros.codigoPlano = undefined;
      state.filtros.status = status;

      state.assinaturas = await servicoControleAssinatura.buscarAssinaturas(state.filtros);
    }

    async function buscarAssinaturas() {
      state.filtros.quantidadeRegistros = 100;
      state.assinaturas = await servicoControleAssinatura.buscarAssinaturas(state.filtros);
    }

    function obtemValoResumo(status: EStatusAssinatura) {
      if (UtilitarioGeral.validaLista(state.resumoAssinaturas)) {
        const resumo = state.resumoAssinaturas.find((c) => c.status === status);
        if (resumo !== undefined) {
          return resumo.quantidade;
        }
      }
      return 0;
    }

    function obterDescricaoRecorrencia(reccorencia:number): string {
      let descricao = '';
      switch (reccorencia) {
        case 1:
          descricao += 'Mensal';
          break;

        case 2:
          descricao += 'Trimestral';
          break;

        case 3:
          descricao += 'Semestral';
          break;

        case 4:
          descricao += 'Anual';
          break;

        default:
          break;
      }

      return descricao;
    }

    function obterCorStatus(status:number): string {
      let cor = '';
      switch (status) {
        case 1:
          cor += '#87e22d';
          break;

        case 2:
          cor += '#03a9f4';
          break;

        case 3:
          cor += '#ffeb3b';
          break;

        case 4:
          cor += '#f44336';
          break;

        case 5:
          cor += '#ff9800';
          break;

        case 6:
          cor += '#607d8b';
          break;

        default:
          break;
      }

      return cor;
    }
    onBeforeMount(async () => {
      telaBase.carregando = true;
      telaBase.identificadorRecurso = 'CONTROLE_ASSINATURAS';
      telaBase.identificadorPermissao = 'AUT_CONTROLE_ASSINATURAS';
      preencherColunas();

      await obterPermissoes(ETipoPermissao.Autorizacoes);
      state.resumoAssinaturas = await servicoControleAssinatura.obterResumo();
      await buscarAssinaturas();
      telaBase.carregando = false;
    });

    async function atualizarAssinaturas() {
      state.resumoAssinaturas = await servicoControleAssinatura.obterResumo();
      await buscarAssinaturas();
    }
    return {
      telaBase,
      gradeBase,
      storeSistema,
      state,
      EStatusAssinatura,
      ECustomRenderRow,
      UtilitarioData,
      adicionarAtalho,
      removerAtalho,
      buscarAssinaturas,
      buscarAssinaturasPorStatus,
      obtemValoResumo,
      obterDescricaoRecorrencia,
      obterCorStatus,
      visualizarAssinatura,
      atualizarAssinaturas,
    };
  },
});
