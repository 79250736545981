import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ant-row" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_4 = { class: "ant-row" }
const _hoisted_5 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_requisicao_modal = _resolveComponent("requisicao-modal")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      keyboard: false,
      visible: _ctx.modalBase.computedVisivel,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modalBase.computedVisivel) = $event))
    }, {
      title: _withCtx(() => [
        _createTextVNode(" Cadastro | Categorias de Permissões ")
      ]),
      closeIcon: _withCtx(() => [
        _createVNode(_component_icone, { nome: "voltar" })
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", null, [
          ((_ctx.props.operacao.tipoPermissaoDados === _ctx.EPermissaoDados.Incluir && _ctx.telaBase.permissaoDados.incluir)
                    || (_ctx.props.operacao.tipoPermissaoDados === _ctx.EPermissaoDados.Visualizar && _ctx.telaBase.permissaoDados.alterar))
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("button", {
                  type: "button",
                  class: "ant-btn ant-btn-lg ant-btn-secondary",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.salvar(true)))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvarNovo), 1)
                ]),
                _createElementVNode("button", {
                  type: "button",
                  class: "ant-btn ant-btn-lg ant-btn-primary ss-btn-acao-destaque",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.salvar(false)))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvar), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_skeleton, {
          loading: _ctx.telaBase.carregando
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, { layout: "vertical" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_a_form_item, {
                      label: "Código",
                      class: "ss-margin-campos"
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "number",
                          class: "ant-input",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.stateModal.categoriaPermissao.codigo) = $event)),
                          disabled: ""
                        }, null, 512), [
                          [_vModelText, _ctx.stateModal.categoriaPermissao.codigo]
                        ])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_a_form_item, {
                      label: "Descrição",
                      class: "ss-margin-campos"
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          maxlength: "250",
                          class: "ant-input",
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.stateModal.categoriaPermissao.descricao) = $event))
                        }, null, 512), [
                          [_vModelText, _ctx.stateModal.categoriaPermissao.descricao]
                        ])
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["loading"]),
        _createVNode(_component_requisicao_modal, {
          visivel: _ctx.modalBase.exibirRequisicaoModal,
          "onUpdate:visivel": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modalBase.exibirRequisicaoModal) = $event)),
          retornoRequisicao: _ctx.modalBase.retornoRequisicao
        }, null, 8, ["visivel", "retornoRequisicao"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}