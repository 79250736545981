
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarEstado from '@/components/Cadastros/Localizacoes/Estados/SelecionarEstado.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { ICidade } from '@/models/Entidades/Cadastros/Localizacoes/ICidade';
import ServicoCidade from '@/servicos/Cadastros/Localizacoes/ServicoCidade';
import storeSistema from '@/store/storeSistema';
import ServicoPAS from '@/servicos/ServicoPAS';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { EStatusCuradoriaCadastro } from '@/models/Enumeradores/PainelAdministrativo/EStatusCuradoriaCadastro';
import { ETipoAmbiente } from '@/models/Enumeradores/PainelAdministrativo/ETipoAmbiente';

export default defineComponent({
  name: 'CidadeModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
    cidadeCuradoria: {
      type: Object as () => ICidade,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    Card,
    SelecionarEstado,
  },
  emits: ['sincronizarRegistro', 'acaoCuradoria', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes,
      preencherPermissoesDados, filtrarPermissaoDadosUsuario, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
      defineNovaOperacao, defineTextoPadraoBotoes, sincronizarRegistro,
    } = useModalBase(props, emit);

    telaBase.identificadorRecurso = 'CADASTRO_CIDADES';
    telaBase.identificadorPermissao = 'PER_CADASTRO_CIDADES';

    const servicoCidade = new ServicoCidade();
    const servicoPAS = new ServicoPAS();

    const state = reactive({
      cidade: {} as ICidade,
      aplicarRegrasEnvioCuradoria: false,
      aplicarRegrasRevisaoCuradoria: false,
    });

    function objetoInicial() {
      state.cidade = { ativo: true } as ICidade;
      state.aplicarRegrasEnvioCuradoria = false;
      state.aplicarRegrasRevisaoCuradoria = false;
    }

    function aprovarCadastro() {
      emit('acaoCuradoria', EStatusCuradoriaCadastro.Aprovado, state.cidade);
      modalBase.computedVisivel = false;
    }

    function reprovarCadastro() {
      emit('acaoCuradoria', EStatusCuradoriaCadastro.Reprovado, state.cidade);
      modalBase.computedVisivel = false;
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      apresentarBarraProgresso();

      if (storeSistema.state.configuracaoApp.tipoAmbiente === ETipoAmbiente.Administracao) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          retorno = await servicoCidade.incluir(state.cidade);
        } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          retorno = await servicoCidade.alterar(state.cidade);
        }

        ocultarBarraProgresso();

        if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
          if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
            sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
          } else {
            sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
          }
          apresentarMensagemSucesso(retorno.mensagem);
          if (salvarNovo) {
            objetoInicial();
            defineNovaOperacao(props.operacao);
          } else {
            modalBase.computedVisivel = false;
          }
        } else {
          apresentarRetornoRequisicao(retorno);
        }
      } else if (storeSistema.state.configuracaoApp.tipoAmbiente === ETipoAmbiente.Plataforma) {
        retorno = await servicoPAS.solicitarCadastroCuradoria(telaBase.identificadorRecurso, state.cidade);

        ocultarBarraProgresso();

        if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
          apresentarMensagemSucesso(retorno.mensagem);
          modalBase.computedVisivel = false;
        } else {
          apresentarRetornoRequisicao(retorno);
        }
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (UtilitarioGeral.objetoValido(props.cidadeCuradoria)) {
          state.aplicarRegrasRevisaoCuradoria = true;
          state.cidade = UtilitarioGeral.instanciaObjetoLocal(props.cidadeCuradoria);
        } else {
          if (props.operacao.listaPermissoesDados.length > 0) {
            await preencherPermissoesDados(props.operacao.listaPermissoesDados);
          } else {
            await obterPermissoes(ETipoPermissao.Dados);
          }
          telaBase.permissaoDados = filtrarPermissaoDadosUsuario(storeSistema.getters.codigoEmpresaOperacao());

          if (storeSistema.state.configuracaoApp.tipoAmbiente === ETipoAmbiente.Administracao) {
            state.aplicarRegrasEnvioCuradoria = false;
            defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
          } else if (storeSistema.state.configuracaoApp.tipoAmbiente === ETipoAmbiente.Plataforma) {
            modalBase.textoBotaoSalvar = 'Sugerir Cadastro';
            state.aplicarRegrasEnvioCuradoria = true;
          }

          if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
            state.cidade = await servicoCidade.obter(props.operacao.codigoRegistro);
          }
        }
      }
      telaBase.carregando = false;
    });
    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      apresentarRetornoRequisicao,
      objetoInicial,
      EPermissaoDados,
      aprovarCadastro,
      reprovarCadastro,
    };
  },
});
