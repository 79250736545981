import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IDTOResumoCuradoriaCadastro } from '@/models/DTO/PainelAdministrativo/IDTOResumoCuradoriaCadastro';
import { ICuradoriaCadastro } from '@/models/Entidades/PainelAdministrativo/ICuradoriaCadastro';
import { IParametrosConsultaCuradoriaCadastro } from '@/models/ParametrosRequisicao/PainelAdministrativo/IParametrosConsultaCuradoriaCadastro';

/**
 * Serviço de Curadorias de Cadastros
 * Fornece todas regras de negócios e lógicas relacionado a curadoria de cadastros.
 */

class ServicoControleAssinatura {
    endPoint = 'painel-administrativo/curadoria-cadastros';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async buscarCuradorias(parametrosConsulta: IParametrosConsultaCuradoriaCadastro): Promise<ICuradoriaCadastro[]> {
      let parametrosAdicionais = '';

      if (parametrosConsulta.quantidadeRegistros > 0) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `QuantidadeRegistros=${parametrosConsulta.quantidadeRegistros}`;
      }

      if (UtilitarioGeral.validaCodigo(parametrosConsulta.codigoContratante)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `CodigoContratante=${parametrosConsulta.codigoContratante}`;
      }

      if (UtilitarioGeral.valorValido(parametrosConsulta.recurso)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Recurso=${parametrosConsulta.recurso}`;
      }

      if (parametrosConsulta.status !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Status=${parametrosConsulta.status}`;
      }

      if (parametrosConsulta.dataSolicitacao !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `DataSolicitacao=${parametrosConsulta.dataSolicitacao}`;
      }

      const result: any = await this.apiERP.get(`${this.endPoint}${parametrosAdicionais}`);
      return result.data;
    }

    public async obterResumo(): Promise<IDTOResumoCuradoriaCadastro[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/resumo`);
      return result.data;
    }

    public async aprovar(curadoriaCadastro: ICuradoriaCadastro): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/aprovar/${curadoriaCadastro.codigo}`, curadoriaCadastro);
      return result.data;
    }

    public async reprovar(curadoriaCadastro: ICuradoriaCadastro): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/reprovar/${curadoriaCadastro.codigo}`, curadoriaCadastro);
      return result.data;
    }

    public async obter(codigo: number): Promise<ICuradoriaCadastro> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}`);
      return result.data;
    }
}
export default ServicoControleAssinatura;
