import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IDTOControleAssinatura } from '@/models/DTO/PainelAdministrativo/IDTOControleAssinatura';
import { IDTOResumoControleAssinatura } from '@/models/DTO/PainelAdministrativo/IDTOResumoControleAssinatura';
import { IControleAssinatura } from '@/models/Entidades/PainelAdministrativo/IControleAssinatura';
import { IParametrosConsultaControleAssinatura } from '@/models/ParametrosRequisicao/PainelAdministrativo/IParametrosConsultaControleAssinatura';

/**
 * Serviço de Controle de Assinaturas
 * Fornece todas regras de negócios e lógicas relacionado a controle de assinaturas.
 */

class ServicoControleAssinatura {
    endPoint = 'painel-administrativo/controle-assinaturas';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async buscarAssinaturas(parametrosConsulta: IParametrosConsultaControleAssinatura): Promise<IDTOControleAssinatura[]> {
      let parametrosAdicionais = '';

      if (parametrosConsulta.quantidadeRegistros > 0) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `QuantidadeRegistros=${parametrosConsulta.quantidadeRegistros}`;
      }

      if (UtilitarioGeral.validaCodigo(parametrosConsulta.codigoContratante)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `CodigoContratante=${parametrosConsulta.codigoContratante}`;
      }

      if (UtilitarioGeral.validaCodigo(parametrosConsulta.codigoPlano)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `CodigoPlano=${parametrosConsulta.codigoPlano}`;
      }

      if (parametrosConsulta.status !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Status=${parametrosConsulta.status}`;
      }

      if (parametrosConsulta.dataVencimento !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `DataVencimento=${parametrosConsulta.dataVencimento}`;
      }

      const result: any = await this.apiERP.get(`${this.endPoint}${parametrosAdicionais}`);
      return result.data;
    }

    public async obterResumo(): Promise<IDTOResumoControleAssinatura[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/resumo`);
      return result.data;
    }

    public async incluir(controleAssinatura: IControleAssinatura): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, controleAssinatura);
      return result.data;
    }

    public async alterar(controleAssinatura: IControleAssinatura): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${controleAssinatura.codigo}`, controleAssinatura);
      return result.data;
    }

    public async obter(codigo: number): Promise<IControleAssinatura> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }
}
export default ServicoControleAssinatura;
