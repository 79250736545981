import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_ctx.props.radioButton)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_a_radio_group, {
          value: _ctx.computedValor,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event)),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.defineValorMarcado($event))),
          "button-style": "solid",
          disabled: _ctx.props.disabled
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_radio_button, { value: 1 }, {
              default: _withCtx(() => [
                _createTextVNode("Chamado/E-mail")
              ]),
              _: 1
            }),
            _createVNode(_component_a_radio_button, { value: 2 }, {
              default: _withCtx(() => [
                _createTextVNode("Chat")
              ]),
              _: 1
            }),
            _createVNode(_component_a_radio_button, { value: 3 }, {
              default: _withCtx(() => [
                _createTextVNode("Telefone")
              ]),
              _: 1
            }),
            _createVNode(_component_a_radio_button, { value: 4 }, {
              default: _withCtx(() => [
                _createTextVNode("Prioritário")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "disabled"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_a_select, {
          value: _ctx.computedValor,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedValor) = $event)),
          onChange: _ctx.defineValorSelecionado,
          class: "ss-tamanho-completo",
          disabled: _ctx.props.disabled
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select_option, { value: 1 }, {
              default: _withCtx(() => [
                _createTextVNode("Chamado/E-mail")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: 2 }, {
              default: _withCtx(() => [
                _createTextVNode("Chat")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: 3 }, {
              default: _withCtx(() => [
                _createTextVNode("Telefone")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: 4 }, {
              default: _withCtx(() => [
                _createTextVNode("Prioritário")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "onChange", "disabled"])
      ]))
}