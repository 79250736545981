
import {
  defineComponent, reactive, onBeforeMount,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import SelecionarItemSincronizarAmbiente from '@/components/PainelAdministrativo/SelecionarItemSincronizarAmbiente.vue';
import SelecionarAcaoNaoSelecionadosSincronizarAmbiente from '@/components/PainelAdministrativo/SelecionarAcaoNaoSelecionadosSincronizarAmbiente.vue';
import SelecionarAmbiente from '@/components/PainelAdministrativo/SelecionarAmbiente.vue';
import SelecionarEstado from '@/components/Cadastros/Localizacoes/Estados/SelecionarEstado.vue';
import SelecionarCidade from '@/components/Cadastros/Localizacoes/Cidades/SelecionarCidade.vue';
import SelecionarBanco from '@/components/Cadastros/Financeiro/SelecionarBanco.vue';
import SelecionarNcm from '@/components/Cadastros/Produtos/SelecionarNcm.vue';
import SelecionarCfop from '@/components/Cadastros/Tributacoes/Cfops/SelecionarCfop.vue';
import SelecionarPlanoAssinatura from '@/components/PainelAdministrativo/SelecionarPlanoAssinatura.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { IOptionString } from '@/core/models/AntDesign/IOption';
import { IDTOSincronizarAmbiente } from '@/models/DTO/PainelAdministrativo/IDTOSincronizarAmbiente';
import { ESelecaoItemSincronizarAmbiente } from '@/models/Enumeradores/PainelAdministrativo/ESelecaoItemSincronizarAmbiente';
import { IDTOSincronizarAmbienteDados } from '@/models/DTO/PainelAdministrativo/IDTOSincronizarAmbienteDados';
import storeSistema from '@/store/storeSistema';
import ServicoSincronizarAmbiente from '@/servicos/PainelAdministrativo/ServicoSincronizarAmbiente';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { useModalBase } from '@/core/composables/ModalBase';

export default defineComponent({
  name: 'SincronizarAmbientes',
  components: {
    Icone,
    Card,
    MensagemSemDados,
    RequisicaoModal,
    SelecionarAmbiente,
    SelecionarEstado,
    SelecionarCidade,
    SelecionarBanco,
    SelecionarNcm,
    SelecionarCfop,
    SelecionarPlanoAssinatura,
    SelecionarItemSincronizarAmbiente,
    SelecionarAcaoNaoSelecionadosSincronizarAmbiente,
  },
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, adicionarAtalho, removerAtalho, apresentarMensagemSucesso,
    } = useTelaBase();

    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);

    const servicoSincronizarAmbiente = new ServicoSincronizarAmbiente();
    const state = reactive({
      recursoSelecionado: '',
      sincronizacao: {} as IDTOSincronizarAmbiente,
      dados: [] as IDTOSincronizarAmbienteDados[],
      todosRecursos: true,
      listaRecursosPadrao: [] as IOptionString[],
      listaRecursos: [] as IOptionString[],
    });
    state.sincronizacao.dados = [];
    state.sincronizacao.ambientes = [];
    state.dados = [];
    state.listaRecursosPadrao = [
      {
        value: 'CADASTRO_PAISES', label: 'Cadastro de Países',
      },
      {
        value: 'CADASTRO_ESTADOS', label: 'Cadastro de Estados',
      },
      {
        value: 'CADASTRO_CIDADES', label: 'Cadastro de Cidades',
      },
      {
        value: 'CADASTRO_BANCOS', label: 'Cadastro de Bancos',
      },
      {
        value: 'CADASTRO_NCMS', label: 'Cadastro de NCMs',
      },
      {
        value: 'CADASTRO_CFOPS', label: 'Cadastro de CFOPs',
      },
      {
        value: 'TABELA_IBPT', label: 'Tabelas IBPT',
      },
      {
        value: 'CADASTRO_PERMISSOES', label: 'Cadastro de Permissões',
      },
      {
        value: 'CADASTRO_MODULOS', label: 'Cadastro de Módulos',
      },
      {
        value: 'CADASTRO_PARAMETROS', label: 'Cadastro de Parâmetros',
      },
      {
        value: 'CADASTRO_PLANOS_ASSINATURA', label: 'Cadastro Planos de Assinatura',
      },
    ];

    function ocultarRecursosSelecionados() {
      let listaRecursos:IOptionString[] = UtilitarioGeral.instanciaObjetoLocal(state.listaRecursosPadrao);
      state.dados.forEach((dados) => {
        listaRecursos = listaRecursos.filter((c) => c.value !== dados.recurso);
      });

      state.listaRecursos = listaRecursos;
    }

    function adicionarSincronizacao(recurso:string) {
      const dadoSincronizacao: IDTOSincronizarAmbienteDados = {} as IDTOSincronizarAmbienteDados;
      dadoSincronizacao.recurso = recurso;
      dadoSincronizacao.selecaoItens = ESelecaoItemSincronizarAmbiente.Todos;
      dadoSincronizacao.acaoNaoSelecionados = 1;
      dadoSincronizacao.codigos = [];
      state.dados.push(dadoSincronizacao);
      state.recursoSelecionado = '';
      ocultarRecursosSelecionados();
    }

    async function iniciarSincronizacao() {
      state.sincronizacao.dados = state.dados;

      apresentarBarraProgresso('Aguarde por favor...');

      const retorno = await servicoSincronizarAmbiente.iniciarSincronizacao(state.sincronizacao);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
      } else {
        apresentarRetornoRequisicao(retorno);
      }

      ocultarBarraProgresso();
    }

    function permitirSincronizacao():boolean {
      if (!UtilitarioGeral.validaLista(state.dados)) { return false; }

      if (!UtilitarioGeral.validaLista(state.sincronizacao.ambientes)) { return false; }

      if (!UtilitarioGeral.valorValido(state.sincronizacao.email)) { return false; }

      return true;
    }

    function estrategiaDefinirRecursos() {
      state.dados = [];
      if (state.todosRecursos) {
        adicionarSincronizacao('CADASTRO_PAISES');
        adicionarSincronizacao('CADASTRO_ESTADOS');
        adicionarSincronizacao('CADASTRO_CIDADES');
        adicionarSincronizacao('CADASTRO_BANCOS');
        adicionarSincronizacao('CADASTRO_NCMS');
        adicionarSincronizacao('CADASTRO_CFOPS');
        adicionarSincronizacao('TABELA_IBPT');
        adicionarSincronizacao('CADASTRO_PERMISSOES');
        adicionarSincronizacao('CADASTRO_MODULOS');
        adicionarSincronizacao('CADASTRO_PARAMETROS');
        adicionarSincronizacao('CADASTRO_PLANOS_ASSINATURA');
      }

      ocultarRecursosSelecionados();
    }

    function removerSincronizacao(index:number) {
      state.dados.splice(index, 1);
      ocultarRecursosSelecionados();
    }

    onBeforeMount(async () => {
      telaBase.carregando = true;
      telaBase.identificadorRecurso = 'SINCRONIZAR_AMBIENTES';
      telaBase.identificadorPermissao = 'AUT_SINCRONIZAR_AMBIENTES';

      await obterPermissoes(ETipoPermissao.Autorizacoes);
      telaBase.carregando = false;
      state.sincronizacao.email = storeSistema.getters.emailUsuarioAutenticado();
      state.todosRecursos = true;
      estrategiaDefinirRecursos();
    });

    return {
      telaBase,
      modalBase,
      state,
      storeSistema,
      adicionarAtalho,
      removerAtalho,
      adicionarSincronizacao,
      ESelecaoItemSincronizarAmbiente,
      iniciarSincronizacao,
      permitirSincronizacao,
      estrategiaDefinirRecursos,
      removerSincronizacao,
      ocultarRecursosSelecionados,
    };
  },
});
