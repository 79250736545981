
import {
  defineComponent,
} from 'vue';

import TelaPadraoCrud from '@/core/components/TelaPadraoCrud.vue';
import { useCrudBase } from '@/core/composables/CrudBase';
import ModuloModal from '@/views/PainelAdministrativo/Modulos/ModuloModal.vue';
import ServicoModulo from '@/servicos/PainelAdministrativo/ServicoModulo';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';

export default defineComponent({
  name: 'Modulo',
  components: {
    TelaPadraoCrud,
    ModuloModal,
  },
  setup() {
    const {
      crudBase, preencherEstrutura, exibirModal, sincronizaTelaCrud,
    } = useCrudBase();
    const servicoModulo = new ServicoModulo();

    crudBase.estrutura = preencherEstrutura();
    crudBase.estrutura.apresentarEmpresas = false;
    crudBase.estrutura.verificarCadastroCompartilhado = false;
    crudBase.estrutura.botaoPrincipalAcao = 'Novo módulo';
    crudBase.estrutura.mensagemExclusao = 'Você confirma a exclusão do módulo';
    crudBase.estrutura.colunasGrade = [
      {
        title: 'Título', dataIndex: 'titulo', key: 'TituloModulo', position: 0, visible: true, ordering: true, fixed: 'left', align: 'left', customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Código', dataIndex: 'codigo', key: 'CodigoModulo', position: 1, visible: false,
      },
      {
        title: 'Identificador', dataIndex: 'identificador', key: 'IdentificadorModulo', position: 2, visible: true, ordering: true, align: 'left',
      },
      {
        title: 'Ações', key: 'acoes', position: 3, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
      },
    ];

    crudBase.estrutura.ordenacaoPadraoGrade.push({ identificador: 'CodigoModulo', ordem: 'ASC' } as IOrdenacao);

    return {
      crudBase,
      servicoModulo,
      exibirModal,
      sincronizaTelaCrud,
    };
  },
});
