import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import storeSistema from '@/store/storeSistema';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IPlanoAssinatura } from '@/models/Entidades/MeuSistema/PlanoAssinatura/IPlanoAssinatura';
import { IDTOPlanoAssinatura } from '@/models/DTO/PainelAdministrativo/IDTOPlanoAssinatura';
/**
 * Serviço de Planos Assinatura
 * Fornece todas regras de negócios e lógicas relacionado a planos de assinaturas.
 */

class ServicoPlanoAssinatura implements IServicoBase<IPlanoAssinatura> {
  endPoint = 'painel-administrativo/planos-assinatura';

  apiERP = new ApiERP();

  public requisicaoSistema(): void {
    this.apiERP = new ApiERP(true);
  }

  public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
    const listaTeste = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
    return listaTeste;
  }

  public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
    return this.apiERP.consultaRapida(this.endPoint, parametrosConsultaRapida);
  }

  public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
    return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
  }

  public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
    return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
  }

  public obterIdentificacaoRegistroGradeCRUD(objeto: IPlanoAssinatura): string {
    return objeto.nome;
  }

  public async incluir(modulo: IPlanoAssinatura): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}/empresa/${storeSistema.getters.codigoEmpresaOperacao()}`, modulo);
    return result.data;
  }

  public async alterar(modulo: IPlanoAssinatura): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/${modulo.codigo}/empresa/${storeSistema.getters.codigoEmpresaOperacao()}`, modulo);
    return result.data;
  }

  public async obter(codigo: number): Promise<IPlanoAssinatura> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${storeSistema.getters.codigoEmpresaOperacao()}`);
    return result.data;
  }

  public async excluir(codigo: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}/empresa/${storeSistema.getters.codigoEmpresaOperacao()}`);
    return result.data;
  }

  public async obterPlanoAssinaturaEmpresa(empresa: number): Promise<IDTOPlanoAssinatura> {
    const result: any = await this.apiERP.get(`${this.endPoint}/empresa/${empresa}`);
    return result.data;
  }
}

export default ServicoPlanoAssinatura;
