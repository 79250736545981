
import {
  defineComponent, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarContratante from '@/components/PainelAdministrativo/SelecionarContratante.vue';
import SelecionarPlanoAssinatura from '@/components/PainelAdministrativo/SelecionarPlanoAssinatura.vue';
import SelecionarRecorrenciaAssinatura from '@/components/PainelAdministrativo/SelecionarRecorrenciaAssinatura.vue';
import SelecionarStatusAssinatura from '@/components/PainelAdministrativo/SelecionarStatusAssinatura.vue';
import SelecionarAmbiente from '@/components/PainelAdministrativo/SelecionarAmbiente.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import ServicoControleAssinatura from '@/servicos/PainelAdministrativo/ServicoControleAssinatura';
import { IControleAssinatura } from '@/models/Entidades/PainelAdministrativo/IControleAssinatura';

export default defineComponent({
  name: 'ControleAssinaturaModal',
  props: {
    visivel: {
      type: Boolean,
    },
    codigo: {
      type: Number,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    SelecionarData,
    SelecionarContratante,
    SelecionarPlanoAssinatura,
    SelecionarRecorrenciaAssinatura,
    SelecionarStatusAssinatura,
    SelecionarAmbiente,
    CampoNumerico,

  },
  emits: ['atualizarAssinaturas'],
  setup(props, { emit }) {
    const {
      telaBase, apresentarMensagemSucesso,
    } = useTelaBase();
    const { modalBase, apresentarRetornoRequisicao } = useModalBase(props, emit);
    const servicoControleAssinatura = new ServicoControleAssinatura();
    telaBase.identificadorRecurso = 'CONTROLE_ASSINATURAS';
    telaBase.identificadorPermissao = 'AUT_CONTROLE_ASSINATURAS';

    const state = reactive({
      controleAssinatura: {} as IControleAssinatura,
    });

    function objetoInicial() {
      state.controleAssinatura = {} as IControleAssinatura;
    }

    async function salvar() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      if (props.codigo === 0) {
        retorno = await servicoControleAssinatura.incluir(state.controleAssinatura);
      } else {
        retorno = await servicoControleAssinatura.alterar(state.controleAssinatura);
      }
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        emit('atualizarAssinaturas');
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.codigo > 0) {
          state.controleAssinatura = await servicoControleAssinatura.obter(props.codigo);
        }
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      objetoInicial,
      EPermissaoDados,
    };
  },
});
